import { FC } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Activity } from "../../types/activity";
import { ChartData, UsageAggregatedData } from "../../types/charts";
import { mobileCheck } from "../../utils/responsive";
import ModelChart from "../charts/ModelChart";
import { Row, Col } from "antd";
import { TOTAL_USAGE_MODEL_NAME, TOKEN_SORT_KEY } from "../../utils/chartsConstants";

const generateDaysArrayForCurrentMonth = (selectedMonth: Dayjs): number[] => {
  const startOfMonth = dayjs(selectedMonth).startOf("month");
  const endOfMonth = dayjs(selectedMonth).endOf("month");
  const daysInMonth = endOfMonth.diff(startOfMonth, "day") + 1;

  return Array.from({ length: daysInMonth }, (_, index) => index + 1);
};

const aggregateDataByModel = (
  items: Activity[],
  modelName: Activity["model_name"],
  selectedMonth: Dayjs,
  isAccumulative: boolean,
): { requestsData: ChartData; tokensData: ChartData; totalRequests: number; totalTokens: number } => {
  const daysInSelectedMonth = generateDaysArrayForCurrentMonth(selectedMonth);
  const initData: UsageAggregatedData = daysInSelectedMonth.reduce(
    (acc, day) => ({ ...acc, [day]: { requests: 0, tokens: 0 } }),
    {},
  );

  let totalRequests = 0;
  let totalTokens = 0;

  items.forEach((item) => {
    if (item.model_name === modelName || modelName === TOTAL_USAGE_MODEL_NAME) {
      const date = dayjs(item.timestamp);
      const dateString = date.format("D");
      totalRequests += item.total_requests;
      totalTokens += item.total_tokens;
      if (initData[dateString]) {
        initData[dateString].requests += item.total_requests;
        initData[dateString].tokens += item.total_tokens;
      }
    }
  });

  if (isAccumulative) {
    // if show accumulative data, loop the objects again, add up each day
    let requestsAccumulation = 0;
    let tokensAccumulation = 0;
    Object.keys(initData).forEach((item) => {
      requestsAccumulation += initData[item].requests;
      tokensAccumulation += initData[item].tokens;
      initData[item] = {
        requests: requestsAccumulation,
        tokens: tokensAccumulation,
      };
    });
  }

  const labels = Object.keys(initData).map((date) => `${date} ${selectedMonth.format("MMM")}`);
  const requestsData = Object.values(initData).map((data) => data.requests);
  const tokensData = Object.values(initData).map((data) => data.tokens);

  return {
    totalRequests,
    totalTokens,
    requestsData: {
      labels,
      datasets: [
        {
          data: requestsData,
          fill: true,
          tension: 0.5,
          backgroundColor: "rgb(75, 192, 192)",
          borderColor: "rgba(75, 192, 192, 0.2)",
        },
      ],
    },
    tokensData: {
      labels,
      datasets: [
        {
          data: tokensData,
          fill: true,
          tension: 0.5,
          backgroundColor: "rgb(109, 141, 196)",
          borderColor: "rgba(109, 141, 196, 0.2)",
        },
      ],
    },
  };
};

type Props = {
  activityData: Activity[];
  uniqueModelNames: string[];
  selectedMonth: Dayjs;
  selectedModel: string;
  isAccumulative: boolean;
};

const UsageChart: FC<Props> = ({ activityData, selectedMonth, uniqueModelNames, selectedModel, isAccumulative }) => {
  const isMobile = mobileCheck();
  return (
    <>
      {uniqueModelNames
        .map((modelName) => {
          return {
            modelName: modelName,
            aggregateData: aggregateDataByModel(activityData, modelName, selectedMonth, isAccumulative),
          };
        })
        .filter((data) => {
          // if no model is selected display everything
          if (!selectedModel) {
            return true;
          } else {
            return data.modelName === selectedModel;
          }
        })
        .sort((a, b) => {
          return b.aggregateData[TOKEN_SORT_KEY] - a.aggregateData[TOKEN_SORT_KEY];
        })
        .map((data) => {
          const { requestsData, tokensData, totalRequests, totalTokens } = data.aggregateData;
          const modelName = data.modelName;
          return (
            <Row key={modelName} justify="space-between">
              <Col span={isMobile ? 24 : 11}>
                <ModelChart
                  chartData={requestsData}
                  modelName={modelName}
                  chartType="API Requests"
                  total={totalRequests}
                />
              </Col>
              <Col span={isMobile ? 24 : 11}>
                <ModelChart chartData={tokensData} modelName={modelName} chartType="Tokens" total={totalTokens} />
              </Col>
            </Row>
          );
        })}
    </>
  );
};

export default UsageChart;
