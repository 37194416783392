import React, { useState } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { routes, billingRoutes } from "../../utils/routes";

const BillingNav: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname.replace("/billing/", ""));

  const items: MenuProps["items"] = [
    {
      label: "Overview",
      key: "overview",
      onClick: () => navigate(routes.billingRoutes + billingRoutes.overviewRoutes),
    },
    {
      label: "Payment methods",
      key: "payment-methods",
      onClick: () => navigate(routes.billingRoutes + billingRoutes.paymentMethodsRoutes),
    },
    {
      label: "Costs",
      key: "costs",
      onClick: () => navigate(routes.billingRoutes + billingRoutes.costsRoutes),
    },
    {
      label: "Billing history",
      key: "history",
      onClick: () => navigate(routes.billingRoutes + billingRoutes.historyRoutes),
    },
  ];

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
  };

  return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />;
};

export default BillingNav;
