import { useState, FC } from "react";
import { Col, Row, Typography, Progress, Button, Flex, Card } from "antd";
import AddPaymentModal from "../../components/billing/AddPaymentModal";
import { mobileCheck } from "../../utils/responsive";
import { BillingOverviewHook } from "../../hooks/useBillingOverview";
import { formatNumber } from "../../utils/chartsConstants";

type Props = {
  billingOverviewHook: BillingOverviewHook;
};

const { Title, Text } = Typography;
const BillingOverview: FC<Props> = ({ billingOverviewHook }) => {
  const isMobile = mobileCheck();
  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false);

  const { overviewData, isFreeUser } = billingOverviewHook;

  const { token_usage } = overviewData!;
  return (
    <Row>
      <Col span={24}>
        {!isFreeUser && (
          <Title level={4} style={{ marginTop: 15 }}>
            Pay As You Go
          </Title>
        )}

        <Title level={5} style={{ marginTop: 10 }}>
          Free Token Usage
        </Title>
        <Flex wrap="wrap" gap="small">
          {token_usage.map((model) => {
            const { total_free_token, free_token_left, model_name } = model;
            const free_token_used = total_free_token - free_token_left;
            return (
              <Card style={{ width: 400, paddingRight: 10 }} key={model_name}>
                <Title level={5} style={{ marginRight: 10, marginTop: 0 }}>
                  Model Name: {model_name}
                </Title>
                <Flex vertical={isMobile}>
                  <Text style={{ marginRight: 10 }}>Used: {formatNumber(free_token_used)}</Text>
                  <Text>Remained: {formatNumber(free_token_left)}</Text>
                </Flex>
                <Progress
                  size="small"
                  style={{ margin: 0 }}
                  percent={parseFloat(((free_token_used / total_free_token) * 100).toFixed(2))}
                />
              </Card>
            );
          })}
        </Flex>

        {isFreeUser && (
          <>
            <Button onClick={() => setAddPaymentModalOpen(true)}>Add payment method</Button>
            <AddPaymentModal open={addPaymentModalOpen} setOpen={setAddPaymentModalOpen} />
          </>
        )}
      </Col>
      <Col style={{ marginTop: 10 }} span={24}>
        <Text type="secondary" italic>
          For details on pricing of the API endpoints，please refer to the{" "}
          <a href="https://docs.voyageai.com/pricing">pricing page</a>
        </Text>
      </Col>
    </Row>
  );
};

export default BillingOverview;
