import { Col, Row, Typography, Descriptions } from "antd";
import LoadingPage from "../Loading";
import { useAuth0 } from "@auth0/auth0-react";
import type { DescriptionsProps } from "antd";

const { Text, Title } = Typography;

const Profile = () => {
  const { user } = useAuth0();

  const getItems = () => {
    const items: DescriptionsProps["items"] = [];

    user?.given_name &&
      items.push({
        key: `user-name`,
        label: "Name",
        span: 2,
        children: <Text>{user.given_name}</Text>,
      });

    user?.email &&
      items.push({
        key: `user-email`,
        label: "Email",
        span: 2,
        children: <Text>{user.email}</Text>,
      });

    return items;
  };

  if (!user) {
    return <LoadingPage />;
  }

  return (
    <Row>
      <Col span={24}>
        <Title level={5} style={{ marginTop: 0 }}>
          User Profile
        </Title>
        <Descriptions size="small" column={4} bordered items={getItems()} />
      </Col>
    </Row>
  );
};

export default Profile;
