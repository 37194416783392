import { FC } from "react";
import { Activity } from "../../types/activity";
import { ChartData } from "../../types/charts";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { Typography } from "antd";

const { Title } = Typography;

export type ModelChartProps = {
  chartData: ChartData;
  modelName: Activity["model_name"];
  chartType: "API Requests" | "Tokens" | "$";
  total: number;
};

const ModelChart: FC<ModelChartProps> = ({ chartData, modelName, chartType, total }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          callback: function (value: string | number, index: number, values: any) {
            // Example: Display every nth label
            const nth = 10; // Adjust as needed
            return index % nth === 0 || index === values.length - 1 ? chartData.labels[value as number] : "";
          },
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          display: false, // Hides grid lines for x-axis
        },
      },
      y: {
        ticks: {
          callback: function (value: any) {
            if (value >= 1e9) {
              return value / 1e9 + "B"; // Billions
            }
            if (value >= 1e6) {
              return value / 1e6 + "M"; // Millions
            }
            if (value >= 1e3) {
              return value / 1e3 + "k"; // Thousands
            }
            return value; // Less than 1000
          },
        },
        min: 0,
      },
    },
    plugins: {
      legend: {
        display: false, // This will hide the legend (including dataset labels)
      },
    },
  };

  return (
    <div>
      <Title style={{ margin: "5px 0" }} level={4}>
        {modelName}
      </Title>
      <Title style={{ margin: "0 0 5px 0" }} level={5}>
        <span>{new Intl.NumberFormat("en-US").format(total)}</span>
        <span style={{ color: "gray", marginLeft: 5 }}>{chartType}</span>
      </Title>
      <div style={{ height: 250 }}>
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default ModelChart;
