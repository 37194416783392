import { FC } from "react";
import { Col, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { RateLimitsData, RateLimitsTableData } from "../../types/account";
import { mobileCheck } from "../../utils/responsive";
import { formatNumber } from "../../utils/chartsConstants";

type Props = {
  rateLimits: RateLimitsData[];
  loading: boolean;
};

const RateLimitsTable: FC<Props> = ({ rateLimits, loading }) => {
  const columns: ColumnsType<RateLimitsTableData> = [
    {
      title: "Endpoint",
      dataIndex: "endpoint",
      key: "endpoint",
      onCell: (_, index) => {
        if (index! % 2 === 1) {
          return { rowSpan: 0 };
        }
        return { rowSpan: 2 };
      },
    },
    {
      title: "",
      dataIndex: "type",
      key: "type",
    },
    { title: "Rate Limits", dataIndex: "value", key: "value" },
  ];

  const isMobile = mobileCheck();

  const processedRateLimits = [];

  for (let i = 0; i < rateLimits.length; i++) {
    const rateLimit = rateLimits[i];
    processedRateLimits.push({
      key: i.toString() + "1",
      endpoint: rateLimit.endpoint,
      type: "RPM",
      value: formatNumber(rateLimit.RPM),
    });
    processedRateLimits.push({
      key: i.toString() + "1",
      endpoint: rateLimit.endpoint,
      type: "TPM",
      value: formatNumber(rateLimit.TPM),
    });
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={processedRateLimits}
            pagination={false}
            size={isMobile ? "small" : "large"}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default RateLimitsTable;
