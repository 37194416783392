import { FC } from "react";
import { Row, Typography, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { routes, billingRoutes } from "../../utils/routes";

const { Text } = Typography;

type Props = {
  isFreeUser: boolean;
  topMargin: boolean;
};
const NoPaymentsAlert: FC<Props> = ({ isFreeUser, topMargin }) => {
  const navigate = useNavigate();

  const getBillingLink = () => (
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        navigate(routes.billingRoutes + billingRoutes.paymentMethodsRoutes);
      }}
    >
      billing
    </a>
  );

  return (
    <Row>
      {isFreeUser && (
        <Alert
          style={{ marginTop: topMargin ? "10px" : 0, marginBottom: "10px" }}
          message={
            <>
              <Text style={{ display: "block", marginTop: 0 }}>
                You have not yet added your payment method in the {getBillingLink()} page and will have reduced rate
                limits of 3 RPM and 10K TPM. Please to add your payment method in the {getBillingLink()} page to unlock
                our standard rate limits. For details, please see our{" "}
                <a href="https://docs.voyageai.com/docs/rate-limits" target="_blank" rel="noreferrer">
                  docs on rate limits
                </a>
                .
              </Text>
              <Text style={{ display: "block", marginTop: 5 }}>Your first 50M tokens are still free.</Text>
            </>
          }
          type="success"
        />
      )}
    </Row>
  );
};

export default NoPaymentsAlert;
