import axios from "../utils/axios";

const billingPath = "billing";
const overviewPath = "/overview";
const paymentMethodsPath = "/payment-methods";
const paymentMethodPath = "/payment-method";
const setDefaultPaymentMethodPath = "/set-default-payment-method";
const subscriptionSetupPath = "/subscription/setup";
const createIntentPath = "/create-intent";
const invoicesPath = "/invoices";
const costPath = "costs";

export const getOverview = () => axios.get(`${billingPath}${overviewPath}`);

export const getPaymentMethods = () => axios.get(`${billingPath}${paymentMethodsPath}`);

export const deletePaymentMethods = (id: string) => axios.delete(`${billingPath}${paymentMethodPath}/${id}`);

export const setDefaultPaymentMethods = (id: string) => axios.put(`${billingPath}${setDefaultPaymentMethodPath}/${id}`);

export const postSubscriptionSetup = (priceIds: string[]) =>
  axios.post(`${billingPath}${subscriptionSetupPath}`, {
    priceIds,
  });

export const postCreateIntent = () => axios.post(`${billingPath}${createIntentPath}`);

export const getInvoices = () => axios.get(`${billingPath}${invoicesPath}`);

export const getCosts = (startDate: string, endDate: string) =>
  axios.get(costPath, {
    params: {
      "start-date": startDate,
      "end-date": endDate,
    },
  });
