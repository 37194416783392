import axios from "axios";
import { notification } from "antd";
import { getBaseUrl } from "./apiConstants";
import { sessionStorageCache } from "../cache";
import gates from "./gates";

const bearerToken = process.env.REACT_APP_BEARER_TOKEN ?? sessionStorageCache.get("bearer");

let headers = {};

if (window.location.hostname === "localhost" || gates["dev-gate"]) {
  headers = {
    "ngrok-skip-browser-warning": "true",
  };
}

const createAxiosClient = () => {
  return axios.create({
    baseURL: getBaseUrl(),
    headers: {
      ...headers,
      authorization: `Bearer ${bearerToken}`,
    },
  });
};

const axiosClient = createAxiosClient();

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const message = error.response?.data?.detail || error.message;
    if (!bearerToken && error.response?.data?.detail === "Bad credentials") {
      // prevent Bad credentials error message when first loggin
      window.location.reload();
    } else {
      notification.error({
        message: "Error",
        description: message,
        duration: 5,
      });
    }

    return Promise.reject(error);
  },
);

export default axiosClient;
