import { FC, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { billingRoutes } from "../utils/routes";
import BillingNav from "../components/navigation/BillingNav";
import BillingOverview from "../pages/billing/BillingOverview";
import BillingPaymentMethods from "../pages/billing/BillingPaymentMethods";
import BillingHistory from "../pages/billing/BillingHistory";
import Costs from "../pages/billing/Costs";
import useBillingOverview from "../hooks/useBillingOverview";
import NoPaymentsAlert from "../components/billing/NoPaymentsAlert";
import LoadingPage from "../pages/Loading";

const BillingRoute: FC = () => {
  const billingOverviewHook = useBillingOverview();

  useEffect(() => {
    billingOverviewHook.fetchOverview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (billingOverviewHook.loading || !billingOverviewHook.overviewData) {
    return <LoadingPage />;
  }

  return (
    <>
      <NoPaymentsAlert topMargin isFreeUser={billingOverviewHook.isFreeUser} />
      <BillingNav />
      <Routes>
        <Route
          path={billingRoutes.overviewRoutes}
          element={<BillingOverview billingOverviewHook={billingOverviewHook} />}
        />
        <Route path={billingRoutes.paymentMethodsRoutes} element={<BillingPaymentMethods />} />
        <Route path={billingRoutes.historyRoutes} element={<BillingHistory />} />
        <Route path={billingRoutes.costsRoutes} element={<Costs />} />
      </Routes>
    </>
  );
};

export default BillingRoute;
